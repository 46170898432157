import React from 'react'
import { Container, Col, Jumbotron, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Hero extends React.Component {
    constructor(props) {
        super(props);
        this.title = this.props.title
        this.description = this.props.description
    }
    
    render() {
        return (
            <section id='banner' className='homepage'>
                <Container>
                    <Row>
                        <Col xs='12' sm='12' md='12' lg='12'>
                            <Jumbotron>
                                <h1>{this.title}</h1>
                                <p>{this.description}</p>
                                <button className='btn btn-primary center-block'><a href='/direct-care/'>Learn More <FontAwesomeIcon icon='chevron-right' /></a></button>
                                <button className='btn btn-primary center-block'><a href='/contact/'>Contact Us <FontAwesomeIcon icon='chevron-right' /></a></button>
                            </Jumbotron>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}

export default Hero