import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Container, Col, Row } from "react-bootstrap"
import Layout from '../components/layout'
import { ContactForm, Name, Email } from '../components/Forms/ContactForms'
import Seo from '../components/seo'
import Hero from '../components/hero'
import NumberFormat from 'react-number-format'
import ReCAPTCHA from 'react-google-recaptcha'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import IndexSeoImage from '../assets/images/hero-home.jpg'

const recaptchaRef = React.createRef()

class Index extends React.Component {
    constructor(props) {
        super(props)

        this.handleUpdate = this.handleUpdate.bind(this)

        this.state = {
            name: ``,
            email: ``,
            phone_number: ``,
            website: ``,
            error: ``,
            loading: false,
        }
    }
    
    handleUpdate = event => {
        if (event.target.name === 'name') {
            this.setState({
                [event.target.name]: event.target.value,
                error: '',
            })
        }
        if (event.target.name === 'email') {
            this.setState({
                [event.target.name]: event.target.value,
                error: '',
            })
        }
        if (event.target.name === 'website') {
            this.setState({
                [event.target.name]: event.target.value,
                error: '',
            })
        }
        if (event.target.name === 'phone_number') {
            this.setState({
                [event.target.name]: event.target.value.replace(/\D/g, ''),
                error: '',
            })
        }
        this.setState({
            [event.target.name]: event.target.value,
            error: '',
        })
    }
    
    onChange(value) {
        console.log('Captcha Value:', value);
    }

    render() {
        return (
            <StaticQuery
            query={graphql`
                query HomeMeta {
                    site {
                        siteMetadata {
                            title
                            description
                            author
                            emailUrl
                            phone
                            google {
                                googleMapAPIKey
                                googleReCaptchaKey
                            }
                            social {
                                facebook {
                                    url
                                }
                                instagramUrl
                                linkedInUrl
                            }
                        }
                    }        
                }
            `}
            render={data => (
                <>
                <Layout page='index'>
                    <Seo title={`Welcome to ${data.site.siteMetadata.title}`} description={data.site.siteMetadata.description} image={IndexSeoImage} keywords={[``]} />
                    <Hero title={`A different approach to primary care centered around you.`} description={`The direct care approach allows for discussion regarding topics rarely covered in your typical office visit, such as, mood, sleep, sexual health, nutrition, energy, and daily life stressors.`} />
                    <section id='welcome'>
                        <Container>
                            <Row>
                                <div className='spacer20'></div>
                                <h2>What Makes Us Different</h2>
                                <div className='card-block'>
                                    <Col className='card' xs='12' sm='12' md='4' lg='4'>
                                        <FontAwesomeIcon className='icon' icon='laptop-medical' />
                                        <h4>Personalized Direct Care</h4>
                                        <p>Getting to know the patient as a person makes your primary care experience more successful.</p>
                                    </Col>
                                    <Col className='card' xs='12' sm='12' md='4' lg='4'>
                                        <FontAwesomeIcon className='icon' icon='universal-access' />
                                        <h4>Accessibility</h4>
                                        <p>Next day telehealth or house calls along with after hours access via phone, text or email.</p>
                                    </Col>
                                    <Col className='card' xs='12' sm='12' md='4' lg='4'>
                                        <FontAwesomeIcon className='icon' icon='heartbeat' />
                                        <h4>Family Approach</h4>
                                        <p>Direct care allows for your doctor to become a member of your extended family.</p>
                                    </Col>
                                </div>
                                <div className='spacer20'></div>
                            </Row>
                        </Container>
                    </section>
                    <section id='care'>
                        <Container fluid>
                            <Row fluid>
                                <Col className='image' xs='12' sm='12' md='6' lg='6'></Col>
                                <Col className='content' xs='12' sm='12' md='6' lg='6'>
                                    <h2>Direct Primary Care</h2>
                                    <p>The average time it takes for a patient to get an appointment with their PCP is two weeks or more. Patients trying to establish care with a physician wait even longer. The average length of a doctor's visit is 17 minutes, less than 6 of those minutes is spent with the patient talking. Busy people who care about their health and wellness shouldn't have to sacrifice their time and daily commitments just to be taken care of by their doctor.</p>
                                    <button className='btn btn-primary center-block'><a href='/direct-care/'>Direct Care <FontAwesomeIcon icon='chevron-right' /></a></button>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section id='bio'>
                        <Container fluid>
                            <Row fluid>
                                <Col className='content' xs='12' sm='12' md='6' lg='6'>
                                    <h2>Dr. Joseph Sambataro, MD</h2>
                                    <p>Dr. Sambataro is a board certified internal medicine physician. Born and raised in North Jersey, he is extremely familiar with the needs of patients living in this fast paced, tightly knit part of the country.</p>
                                    <button className='btn btn-primary center-block'><a href='/about/'>Read More <FontAwesomeIcon icon='chevron-right' /></a></button>
                                </Col>
                                <Col className='image' xs='12' sm='12' md='6' lg='6'></Col>
                            </Row>
                        </Container>
                    </section>
                    <section id='wellness'>
                        <Container fluid>
                            <Row fluid>
                                <Col className='image' xs='12' sm='12' md='6' lg='6'></Col>
                                <Col className='content' xs='12' sm='12' md='6' lg='6'>
                                    <h2>Additional Wellness &amp; Life Services</h2>
                                    <p>Explore our additional services provided through our care.</p>
                                    <button className='btn btn-primary center-block'><a href='/wellness/'>Experience More <FontAwesomeIcon icon='chevron-right' /></a></button>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section id='contact' className='lightBlueColorBkgd'>
                        <Container>
                            <Row>
                                <Col xs='12' sm='12' md='5' lg='5'>
                                    <div className='spacer40 hidden-xs hidden-sm'></div>
                                    <h2 class='whiteColor'>Reach out today!</h2>
                                    <div className='spacer20'></div>
                                    <p class='whiteColor'><strong>{data.site.siteMetadata.title}</strong><br /><a className='reverse' href={`mailto:${data.site.siteMetadata.emailUrl}`}>{data.site.siteMetadata.emailUrl}</a><br />{data.site.siteMetadata.phone}</p>
                                </Col>
                                <Col xs='12' sm='12' md='7' lg='7'>
                                    <ContactForm title={`Contact Us`} subtitle={``} error={this.state.error} reverse={true}>
                                        <Name handleUpdate={this.handleUpdate} email={this.state.email} autoComplete='on' />
                                        <Email handleUpdate={this.handleUpdate} email={this.state.email} autoComplete='on' />
                                        <fieldset>
                                            <span className='label'>Phone:</span>
                                            <NumberFormat id='phone_number' placeholder='+1 (###) ###-####' onChange={this.handleUpdate} name='phone_number' value={this.state.phone_number} type='tel' format='+1##########' mask='_' />
                                        </fieldset>
                                        <fieldset className='required'>
                                            <textarea id='message' name='message' aria-label='message' placeholder='Type your message here...' required></textarea>
                                        </fieldset>
                                        <ReCAPTCHA ref={recaptchaRef} sitekey={data.site.siteMetadata.google.googleReCaptchaKey} onChange={this.onChange} />
                                        <div className='spacer20'></div>
                                        <button type='submit' className='btn btn-secondary btn-block' disabled={this.state.loading}>
                                            {this.state.loading ? null : 'Send'}
                                            {this.state.loading && (
                                                <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true' />
                                            )}
                                            {this.state.loading ? null : (<FontAwesomeIcon icon="chevron-right" />)}
                                        </button>
                                    </ContactForm>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </Layout>
                </>
            )}
            />
        )
    }
}

export default Index